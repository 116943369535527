import { TemplateFragmentLoader } from './templates';

export class LandingPageUi {
    constructor() {}

    initContactSection() {
        const loader = new TemplateFragmentLoader("#contact-form", "/contact");
        loader.loadTemplateFragment();

    }
}
