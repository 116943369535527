import $ from "jquery";
import * as Constants from './constants';


export class TemplateFragmentLoader {

    constructor(targetDiv,  url, callback) {
        this.targetDiv = targetDiv;
        this.url = url;
        this.callback = callback;
    }

    loadTemplateFragment(swap='innerHTML') {
      htmx.ajax(Constants.getMethod, this.url, { target: this.targetDiv, swap: swap });
    }

}
