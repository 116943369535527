import {SideNavigation, DashboardFragment} from "./shared/navigation";
import {TemplateFragmentLoader} from "./templates";
import {changePasswordUrl, emailManagementUrl, logoutUrl, getMethod} from "./constants";

class UserDashboardFragment extends DashboardFragment {
    constructor(fragmentName, isLoaded, url) {
        super(fragmentName, isLoaded, url);
    }

    isUserProfileFragment() {
        return this.fragmentName === 'user-profile';
    }

    isEmailManagementFragment() {
        return this.fragmentName === 'email-management';
    }

    isChangePasswordFragment() {
        return this.fragmentName === 'change-password';
    }

}

export class UserSideNavigation extends SideNavigation {

    constructor(injectIntoId) {
        super();
        this.injectIntoId = injectIntoId;
        this.fragments = [
        new UserDashboardFragment("user-profile", false, undefined),
        new UserDashboardFragment("email-management", false, emailManagementUrl),
        new UserDashboardFragment("change-password", false, changePasswordUrl),
    ];
    }

    loadUserProfile(url) {
        this.fragments
            .filter(fragment => !fragment.isUserProfileFragment())
            .forEach(fragment => fragment.isLoaded = false);
        const userProfileFragment = this.fragments.find(fragment => fragment.isUserProfileFragment());
        userProfileFragment.url = url;
        this.load(userProfileFragment);
    }

    loadEmailManagement() {
        this.fragments
            .filter(fragment => !fragment.isEmailManagementFragment())
            .forEach(fragment => fragment.isLoaded = false);
        this.load(this.fragments.find(fragment => fragment.isEmailManagementFragment()));
    }

    loadChangePassword() {
        this.fragments
            .filter(fragment => !fragment.isChangePasswordFragment())
            .forEach(fragment => fragment.isLoaded = false);
        this.load(this.fragments.find(fragment => fragment.isChangePasswordFragment()));
    }

    logout() {
        htmx.ajax(getMethod, logoutUrl);
    }

    load(fragment) {
        const loader = new TemplateFragmentLoader(this.injectIntoId, fragment.url);
        loader.loadTemplateFragment();
        fragment.isLoaded = true;
    }
}
