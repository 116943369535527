
import {TemplateFragmentLoader} from "./templates";

export class FindJobsUi {

    constructor() {

    }

    setFilterJobListFormHiddenInputs() {
        const [title, country, region] = [$("#title").val(), $("#country").find(":selected").val(), $("#region-select").find(":selected").val()];
        $("#search_title").val(title);
        $("#search_country").val(country);
        $('#search_region').val(region);

    }

    clearFindJobFilters() {
        // /app/job/search
        $("#filter-form").trigger("reset");
        $("#filter-form-mobile").trigger("reset");
        const [title, country, region] = [$("#title").val(), $("#country").find(":selected").val(), $("#region-select").find(":selected").val()];
        const loader = new TemplateFragmentLoader("#jobs_table", "/app/job/search?title=" + title + "&country=" + country + "&region=" + region);
        loader.loadTemplateFragment('outerHTML');
        this.setFilterJobListFormHiddenInputs();

    }
}

export class JobDetailsUi {

    constructor() {

    }

    initModal() {
        const elem = document.getElementById("apply-job-modal");
        const modal = new bootstrap.Modal(elem)
        htmx.on("htmx:afterSwap", (e) => {
            // Response targeting #dialog => show the modal
            if (e.detail.target.id == "apply-job-modal-form") {
              modal.show()
            }
        })
    }

    initialize() {
        this.initModal();
    }
}
