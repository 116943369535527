export class SideNavigation {
    constructor() {
        if(this.constructor === SideNavigation) {
            throw new Error("Abstract class can't be instantiated.");
        }
    }

    load(fragment) {
        throw new Error("Method load() must be implemented.");
    }
}

export class DashboardFragment {
    constructor(fragmentName, isLoaded, url) {
        this.fragmentName = fragmentName;
        this.isLoaded = isLoaded;
        this.url = url;
    }


}
