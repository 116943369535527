import $ from "jquery";
import * as Constants from './constants';

// update region and subregion selects when user selects a country or a region
class LocationListsLoader {

    constructor(targetDiv, targetSelect, url, floating) {
        this.targetDiv = targetDiv;
        this.targetSelect = targetSelect;
        this.url = url;
        this.floating = floating;
    }

    loadList(selectedItem) {
        $(this.targetSelect).prop('disabled', true);
        htmx.ajax(Constants.getMethod, this.getUrl(selectedItem), { target: this.targetDiv, swap: 'innerHTML' }).then(() => {
            $(this.targetSelect).prop('disabled', false);
        });
    }

    getUrl(selectedItem) {
        return this.floating? this.url + selectedItem + '?floating=' + this.floating : this.url + selectedItem;
    }
}


export class RegionUtils {
    constructor() {}

    updateRegionList(selectedCountry, targetDiv, selectId, floating) {
        const loader = new LocationListsLoader(targetDiv,
            selectId, Constants.regionUrl, floating);
        return loader.loadList(selectedCountry);
    }
}
