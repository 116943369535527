import '../sass/project.scss';
import { RegionUtils } from './location';
import 'select2'
import {EmployerSideNavigation, EmployerDashboard} from "./employer";
import { Configuration } from "./config"
import { CandidateDashboard, CandidateSideNavigation } from './candidate';
import { Toaster } from './shared/toast';
import { UserSideNavigation } from './user';
import { FindCandidatesUi } from './candidate-search';
import { FindJobsUi, JobDetailsUi } from './job-search';
import { LandingPageUi  } from './landing-page';
import '@selectize/selectize';
import 'bootstrap-datepicker';



/* Project specific Javascript goes here. */

class Application {

    constructor() {
        this.config = new Configuration();
        this.toaster = new Toaster();
        this.employerSideNavigator = new EmployerSideNavigation("#dashboard-fragment");
        this.candidateSideNavigator = new CandidateSideNavigation("#dashboard-fragment");
        this.userSideNavigator = new UserSideNavigation("#dashboard-fragment");

    }

}

export const app = new Application();
export const employerDashboard = new EmployerDashboard();
export const jobSearch = new FindJobsUi();
export const candidateSearch = new FindCandidatesUi();
export const landingPage = new LandingPageUi();
export const candidateDashboard = new CandidateDashboard();
export const jobDetailsUi = new JobDetailsUi();
export const regionUtils = new RegionUtils();
