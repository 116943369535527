export class Configuration{
    constructor() {
        this.initHtmxDisableElementPlugin();
    }

    initHtmxDisableElementPlugin() {
        // htmx disable element extension
        htmx.defineExtension('disable-element', {
            onEvent: function(name, evt) {
                let elt = evt.detail.elt;
                try {
                    let target;
                    let targetElement;
                    try {
                        target = elt.getAttribute("hx-disable-element");
                        targetElement = (target == "self") ? elt : document.querySelector(target);
                    } catch (err) {

                    }
                    if (name === "htmx:beforeRequest" && targetElement) {
                        targetElement.disabled = true;
                    } else if (name == "htmx:afterRequest" && targetElement) {
                        targetElement.disabled = false;
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        });
    }
}
