import {TemplateFragmentLoader} from "./templates";

export class FindCandidatesUi {

    constructor() {

    }

    setFilterCandidatesListFormHiddenInputs() {
        const [title, country, region] = [$("#title").val(), $("#country").find(":selected").val(), $("#region-select").find(":selected").val()];
        $("#search_title").val(title);
        $("#search_country").val(country);
        $('#search_region').val(region);

    }

    clearFindCandidatesFilters() {
        // /app/job/search
        $("#filter-form").trigger("reset");
        $("#filter-form-mobile").trigger("reset");
        const [title, country, region] = [$("#title").val(), $("#country").find(":selected").val(), $("#region-select").find(":selected").val()];
        const loader = new TemplateFragmentLoader("#jobs_table", "/app/candidate/search?title=" + title + "&country=" + country + "&region=" + region);
        loader.loadTemplateFragment('outerHTML');
        this.setFilterCandidatesListFormHiddenInputs();

    }
}
